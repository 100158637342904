<template>
  <div>
    <b-card :header-text-variant="colorTextHeader"
            :header-bg-variant="colorBgHeader"
            :header-border-variant="colorBgHeader"
            :footer-text-variant="colorTextFooter"
            :footer-bg-variant="colorBgFooter"
            :footer-border-variant="colorBgFooter"
            class="widget-custom"
            :no-body="true">

      <!-- CABECERA -->
      <div slot="header" class="widget-header-custom" v-if="!errorLoad">      
        <strong> {{this.widget.title}} </strong>
        
        <b-link href="javascript:void(0);" v-b-tooltip.hover :title="titleFilters" @click="openFilter()" v-if="showApplyFilters">
          <b-icon icon="filter" animation="fade" font-scale="2" class="ml-2"></b-icon>
        </b-link>

        <i class="fa fa-info pull-right widget-info-custom" v-b-tooltip.hover :title="'(ID:' + widget.id + ') - ' + widget.description"></i>
      </div>

      <!-- SPINER DE CARGA -->
      <div class="text-center p-3" v-if="!finishLoad" >
        <b-spinner label="Spinning"></b-spinner>
      </div>
      
      <!-- NO CONFIGURADO -->    
      <div class="text-center p-2" v-if="widget.configured==0">      
        <b-alert class="mt-1" variant="warning" show>
          <b-icon icon="code-slash" class="h1"></b-icon> <br>
          El widget aun esta en período de desarrollo<br><br>
        <b>Tipo de Widget</b> <br>
        <b-badge variant="secondary">{{this.widget.type}}</b-badge>        
        </b-alert>
      </div> 
      <div v-else>
        <!-- GRAFICO -->
        <b-card-text v-if="!errorLoad && hasAccess">
          <List v-if="widget.type=='list' && !this.iseEmpty(widget.data)" :data="widget.data"/>
          <Indicator v-if="widget.type=='indicator' && !this.iseEmpty(widget.data)" :data="widget.data" :colorText="primaryColor" />    
          <Iframe v-if="widget.type=='iframe' && widget.external_url" :data="widget.external_url" :height="widget.height" :key="forceIframeSrc" />    
          
          <div v-if="validChartError">    
            <BarChartIndicator v-if="widget.type=='bar-indicator' && !this.iseEmpty(widget.data)" :chartData="prepareDatasets(widget.data)" :options="prepareOptions(widget.data)" :indicators="prepareIndicators(widget.data)" />
            <BarChart v-if="widget.type=='bar' && !this.iseEmpty(widget.data)" :chartData="prepareDatasets(widget.data)" :options="prepareOptions(widget.data)" />
            <PieChart v-if="widget.type=='pie' && !this.iseEmpty(widget.data)" :chartData="prepareDatasets(widget.data)" :options="prepareOptions(widget.data)" />        
            <DoughnutChart v-if="widget.type=='doughnut' && !this.iseEmpty(widget.data)" :chartData="prepareDatasets(widget.data)" :options="prepareOptions(widget.data)" />        
          </div>
          <div v-else>
            <div class="text-default text-center p-2">      
              <b-icon icon="eye-slash" class="h3"></b-icon><br>
              {{this.widget.data}}
            </div>          
          </div>

          <!-- SI ES ACTUALIZACION MANUAL -->       
          <div v-if="finishLoad && updateManual && ((widget.type=='iframe' && !widget.external_url) || (widget.type!='iframe' && this.iseEmpty(widget.data)))" class="text-center">
            <b-button type="button" 
                      variant="outline-dark" 
                      class="btn-pill mt-3" 
                      @click="forceUpdate('manual','load')" 
                      v-b-tooltip.hover title="Obtener datos manualmente">
              <i class="fa fa-hand-o-up"></i> OBTENER DATOS
            </b-button>                 
          </div>

          <b-card-text v-if="finishLoad"
                      class="small text-muted text-right widget-ultima-actualizacion"                    
                      @click="forceUpdate('auto','click')">

            <!-- PIE -->          
            <span class="widget-router-custom" v-if="widget.router && !errorLoad && hasAccess">
              <i @click="go()" class="pb-0 pt-0" 
                              v-b-tooltip.hover.left
                              title="Acceso directo. Click para acceder">
                Ir >>
              </i>
            </span>
            <span class="widget-ultima-actualizacion"
                  v-b-tooltip.hover.left
                  title="Última actualización. Click para forzar actualización"
                  style="position: relative;">            
              <i class="fa fa-clock-o"></i> {{moment().format('HH:mm:ss')}}
            </span>
          </b-card-text>
        </b-card-text>

        <!------------------------------------------------------------------------>
        <!------------------------------------------------------------------------>

        <!-- SIN PERMISOS -->
        <div class="text-default text-center p-2" v-if="!errorLoad && !hasAccess && finishLoad">      
          <b-icon icon="eye-slash" class="h3"></b-icon><br>
          Su usuario no tiene acceso al contenido de este widget
        </div>

        <!-- ERROR DE CONFIGURACION -->
        <div class="text-danger text-center p-2" v-if="errorLoad && finishLoad">
          Error en la configuración del widget
        </div>
      </div>

    </b-card>
 
    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <!-- CRUD FILTER --> 
    <b-modal v-model="modal.form.active"
            header-bg-variant="dark"
            header-text-variant="white"
            size="md"
            centered
            id="widget-modal-filter">
      <div slot="modal-header">
        {{this.modal.form.title}}
      </div>

      <!-- FECHA -->
      <b-row v-if="getShowFilter('date_start_end')">
        <b-col md="6" class="pr-0">
          <b-form-input type="date" size="sm" v-model="filter.date_start" placeholder="Fecha Desde"></b-form-input>
        </b-col>
        <b-col md="6" class="pl-0">
          <b-form-input type="date" size="sm" v-model="filter.date_end" placeholder="Fecha Hasta"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="mt-2" v-if="getShowFilter('months_years')">
        <b-col md="6" class="pr-0">      
          <b-form-select size="sm" v-model="filter.months" :options="opt.months" class="mb-2"></b-form-select>
        </b-col>
        <b-col md="6" class="pl-0">      
          <b-form-select size="sm" v-model="filter.years" :options="opt.years" class="mb-2"></b-form-select>
        </b-col>        
      </b-row>
      <b-row class="mt-2" v-if="getShowFilter('employees') && !isEmployee">
        <b-col md="12">      
          <v-select v-model="filter.employees" :options="opt.employees" placeholder="Project Manager" :multiple="true" :select-on-tab="true"></v-select>                
        </b-col>
      </b-row>      

      <div slot="modal-footer">
        <b-button variant="outline-secondary" size="md" class="mr-1" @click="modal.form.active=false">Cerrar</b-button>
        <b-button variant="dark" size="md" @click="applyFilter()">Filtrar</b-button>          
      </div>
    </b-modal>

  </div>
</template>

<script>
  import moment from 'moment'
  import Funciones from '@/handler/funciones'
  import serviceAPI from './services'
  import Session from '@/handler/session'
  import {EventBus} from '@/handler/event-bus'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Helper from '@/handler/helper'
  import Indicator from './_indicator'
  import List from './_list'
  import BarChart from './_chartBar'
  import PieChart from './_chartPie'
  import DoughnutChart from './_chartDoughnut'  
  import Iframe from './_iframe.vue'
  import BarChartIndicator from './_chartBarIndicator'
  import Param from '@/config/parameters'
  import StorageLocal from '@/handler/storageLocal'

  export default {
    components: {
      BarChart,
      PieChart,
      DoughnutChart,
      List,
      Indicator,
      Iframe,
      BarChartIndicator,
    },
    props: {
      configWidget: {
        type: Object,
        required: true
      },
      reference: {
        type: String,
        required: true
      },
      hasAccess: {
        type: Boolean,
        required: true
      },
      colorTextHeader: {
        type: String,
        default: 'white'
      },
      colorBgHeader: {
        type: String,
        default: 'dark'
      },
      colorTextFooter: {
        type: String,
        default: 'dark'
      },
      colorBgFooter: {
        type: String,
        default: 'secondary'
      },
      isCustom: {
        type: Boolean,        
        default: false,
      }
    },
    data: () => {
      return {
        widget: {},
        widgetCallFunctionParams: {},        
        finishLoad: false,
        errorLoad: false,
        config: {},
        interval: null,
        primaryColor: '',
        modal: {
          form: {
            active: false,
            title: ''
          },  
        },
        filter: {          
          date_start: '',
          date_end: '',          
          months: '',
          years: '',
          employees: '',
        },
        opt: {
          months: [],
          years: [],    
          employees: [],      
        },
        updateManual: false,
        forceIframeSrc: 0,
      }
    },
    mounted() {
      this.updateManual = Helper.hasParametersAccess(Param.P63)

      this.load() 
      
      if(!this.updateManual) {
        this.loadCallFunction()
      }      
      
      this.setColorPrimary()    

      this.prepareFilter()    
    },
    destroyed () {      
      clearInterval(this.interval)      
    },    
    computed: {
      validChartError() {
        var result = true
        if(this.widget.type != 'list' && this.widget.type != 'indicator'){

          if(typeof(this.widget.data) == 'string') {          
            result = false
          }
          
        }  
        return result      
      },
      showApplyFilters(){
        if(this.widgetCallFunctionParams.length) {
          return true
        } else {
          return false
        }
      },   
      titleFilters() {
        var titleFilters = ''        

        if(this.getShowFilter('date_start_end')) {
          if(this.filter.date_start && this.filter.date_end) {
            titleFilters = 'Desde: ' + moment(this.filter.date_start).format('DD/MM/YYYY') + ' - Hasta: ' + moment(this.filter.date_end).format('DD/MM/YYYY')          
          } 
        }
        
        if(this.getShowFilter('months_years')) {
          if(this.filter.months && this.filter.years) {
            if(titleFilters) {
              titleFilters = titleFilters + ' | '
            } 

            titleFilters = titleFilters + this.getNameMonths(this.filter.months) + ' ' + this.filter.years           
          }
        }

        if(this.getShowFilter('employees')) {
          if(this.filter.employees) {
            if(titleFilters) {
              titleFilters = titleFilters + ' | '
            } 

            if(this.filter.employees) {
              this.filter.employees.forEach(element => {
                titleFilters = titleFilters + element['label'] + ', '    
              });
              titleFilters = titleFilters.slice(0,-2)
            }          
          }        
        }

        if(!titleFilters) {
          titleFilters = 'Aplicar Filtros'
        }
        
        return titleFilters
      },
      isEmployee(){
        if(Helper.getEmployee()) {
          if(Helper.getEmployee().permission_admin_projects) {
            return false  
          } else {
            return true
          }                    
        } else {
          return false
        }
      },         
    },
    methods: {
      iseEmpty(valor) {
        return (
            valor === 0 || 
            valor === "" || 
            valor === null || 
            valor === undefined || 
            (Array.isArray(valor) && valor.length === 0)
        )
      },      
      load () {
        this.config = {
          profile_id: this.configWidget.profile_id,
          module_id: this.configWidget.module_id,
          reference: this.reference,
          filter: this.filter,
          stringCallFunction: false, 
        }

        var result = null
        var result_alt = null
        if(this.isCustom) {
          result = serviceAPI.mostrarByReferenciaCustom(this.config)
        } else {
          result_alt = JSON.parse(StorageLocal.getValue('configWidget'));
          if(!result_alt) {
            result = serviceAPI.mostrarByReferencia(this.config) 
          }          
        }

        if(result_alt) {
          var data = result_alt.find(item => item.reference === this.reference);
          
          data.data = this.initValueWidget(data)
          
          this.widget = data
          this.finishLoad = true
          this.errorLoad = false

          this.reload(data.configured, data.time_refresh)          
        } else {
          result.then((response) => {          
            var data = response.data

            data.data = this.initValueWidget(data)
            
            this.widget = data
            this.finishLoad = true
            this.errorLoad = false

            this.reload(data.configured, data.time_refresh)
          })
          .catch(error => {
            this.finishLoad = true
            this.errorLoad = false
            this.$awn.alert(ErrorToken.valid(error))          
          });
        }              
      },
      loadCallFunction() {
        this.config = {
          profile_id: this.configWidget.profile_id,
          module_id: this.configWidget.module_id,
          reference: this.reference,
          filter: this.filter,
          stringCallFunction: true, 
        }

        var result = null
        if(this.isCustom) {
          result = serviceAPI.mostrarByReferenciaCustom(this.config)
        } else {
          result = serviceAPI.mostrarByReferencia(this.config)
        }

        result.then((response) => {
          var data = response.data
          this.widgetCallFunctionParams = data      

          this.forceUpdate('auto','load')    
        })
        .catch(error => {          
          this.$awn.alert(ErrorToken.valid(error))          
        });
      },
      reload(configured, timeRefresh) {    
        if (configured) {                              
          if(!this.updateManual) {

            this.interval = setInterval(() => {     

              this.config.stringCallFunction = false
              
              var result = null
              if(this.isCustom) {
                result = serviceAPI.mostrarByReferenciaCustom(this.config)
              } else {
                result = serviceAPI.mostrarByReferencia(this.config)
              }

              result.then((response) => {
                var data = response.data
                data.data = this.initValueWidget(data)
                this.widget = data
              })
              .catch(error => {
                this.$awn.alert(ErrorToken.valid(error));        
              })              
            }, timeRefresh);
          }  
        }
      },
      forceUpdate(type='auto', action='') {
        if(type=='manual') {
          this.finishLoad = false
        }

        this.config.stringCallFunction = false
        
        var result = null
        if(this.isCustom) {
          result = serviceAPI.mostrarByReferenciaCustom(this.config)
        } else {
          result = serviceAPI.mostrarByReferencia(this.config)
        }

        result.then((response) => {
          var data = response.data
          data.data = this.initValueWidget(data)
          this.widget = data

          if(action == 'click') {
            if(this.widget.type == 'iframe') {
              this.forceIframeSrc++
            }
          }

          if(type=='manual') {
            const result_alt = JSON.parse(StorageLocal.getValue('configWidget'));
            if(result_alt) {
              var result_data = result_alt.find(item => item.reference === this.reference);
            }
            const call = result_data.dataCallback.indexOf('$');
            if (call !== -1) {
              const match = result_data.dataCallback.match(/\(([^)]+)\)/);
              if (match) {                
                const arrParam = match[1].split(',').map(param => param.trim());
                this.widgetCallFunctionParams = arrParam
              } 
            }   

            this.finishLoad = true
          }          
        })
        .catch(error => {
          this.$awn.alert(ErrorToken.valid(error));        
        })
      },
      go () {
        this.$router.push({ name: this.widget.router })        
      },
      initValueWidget(data) {
        var value = null
        if(data.data == null) {
          switch (data.type) {
            case 'indicator':
              value = 0
              break

            case 'pie':
              value = []
              break

            case 'bar':
              value = []
              break
          }
        } else {
          value = data.data
        }
        return value
      },
      setColorPrimary() {
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
        }
      },
      prepareDatasets(data) {  
        return data.chartData
      },
      prepareOptions(data) {                    
        return data.options
      },
      prepareIndicators(data) {                    
        return data.indicators
      },      
      prepareFilter() {
        // prepare months
          this.opt.months.push({value:'01', text:'Enero'})
          this.opt.months.push({value:'02', text:'Febrero'})
          this.opt.months.push({value:'03', text:'Marzo'})
          this.opt.months.push({value:'04', text:'Abril'})
          this.opt.months.push({value:'05', text:'Mayo'})
          this.opt.months.push({value:'06', text:'Junio'})
          this.opt.months.push({value:'07', text:'Julio'})
          this.opt.months.push({value:'08', text:'Agosto'})
          this.opt.months.push({value:'09', text:'Septiembre'})
          this.opt.months.push({value:'10', text:'Octubre'})
          this.opt.months.push({value:'11', text:'Noviembre'})
          this.opt.months.push({value:'12', text:'Diciembre'})
        // fin

        // prepare yeras
          var yearNow = moment().format('YYYY')
          var yearLimit = 2015

          for (let index = yearLimit; index <= yearNow; index++) {
            this.opt.years.push({
              value: index,
              text: index,
            })
          } 
          this.opt.years = this.opt.years.reverse()    
        // fin

        // prepare employee
          this.filterLoadEmployees()
        // fin
      },
      openFilter(){        
        if(!this.filter.months) {
          this.filter.months = moment().format('MM')
        }
        
        if(!this.filter.years) {
          this.filter.years = moment().format('YYYY')
        }

        if(!this.filter.date_start) {
          this.filter.date_start = moment().startOf('month').format('YYYY-MM-DD');
        }

        if(!this.filter.date_end) {
          this.filter.date_end = moment().endOf('month').format('YYYY-MM-DD');
        }
      
        this.modal.form.title = "Filtrar '" + this.widget.title + "'"
        this.modal.form.active = true
      }, 
      applyFilter() {
        this.config.filter = this.filter        
        this.modal.form.active = false        
        this.forceUpdate('auto','filter')
      },
      getShowFilter(value) {
        var status = false
        
        if(this.widgetCallFunctionParams.length) {
          this.widgetCallFunctionParams.forEach(element => {
            if(value == 'date_start_end') {     
              if(element == '$date_start' || element == '$date_end') {
                status = true
              }
            }
            
            if(value == 'months_years') {          
              if(element == '$months' || element == '$years') {
                status = true
              }
            }

            if(value == 'employees') {          
              if(element == '$employees') {
                status = true
              }
            }            
          });
        }                  

        return status 
      },
      getNameMonths(value) {
        var name = ''
        this.opt.months.forEach(element => {
          if(element.value == value) {
            name = element.text
          }
        });
        return name
      },
      filterLoadEmployees() {
        var result_alt = JSON.parse(StorageLocal.getValue('configWidgetEmployee'));
        if(result_alt) {          
          var data = result_alt

          this.opt.employees = []
          data.forEach(element => {            
            this.opt.employees.push({ code: element.id, label: element.name })            
          });
        } else {
          var result = serviceAPI.obtenerEmpleado()        
          result.then((response) => {
            var data = response.data    

            this.opt.employees = []
            data.forEach(element => {            
              this.opt.employees.push({ code: element.id, label: element.name })            
            });          
          })   
        }
      },      
    }
  }
</script>
<style scoped>
  .widget-info-custom {
    right: 10px;
    position: absolute;    
  }
  .widget-header-custom {
    display: flex;
    align-items: center;    
  }
</style>
<style>
  #widget-modal-filter .modal-header {
    padding: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;    
  }
  #widget-modal-filter .modal-footer {
    padding: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;        
  }
</style>