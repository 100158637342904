<template>
  <div class="widget-embed-custom" :style="'height:' + this. getHeight()">
    <b-embed
      type="iframe"      
      :src="this.data"
      allowfullscreen
      :style="'height:' + this. getHeight()"
    ></b-embed>        
  </div>  
</template>

<script>
  import {EventBus} from '@/handler/event-bus'

  export default {
    props: {
      data: {
        type: String,
        required: true
      },  
      height: {
        type: Number,
        default: 0
      },       
    },
    methods: {
      getHeight() {
        return this.height > 0 ? this.height + 'px' : '100%'
      }
    },
  }
</script>
<style scoped>
  .widget-embed-custom .embed-responsive {
    height: inherit;
  }
</style>